import request from '@/utils/request'

// 查询保险受保对象管理列表
export function listInsuranceObjects (query) {
  return request({
    url: '/iot/insuranceObjects/list',
    method: 'get',
    params: query
  })
}

// 查询保险受保对象管理详细
export function getInsuranceObjects (id) {
  return request({
    url: '/iot/insuranceObjects/' + id,
    method: 'get'
  })
}

// 新增保险受保对象管理
export function addInsuranceObjects (data) {
  return request({
    url: '/iot/insuranceObjects',
    method: 'post',
    data: data
  })
}

// 修改保险受保对象管理
export function updateInsuranceObjects (data) {
  return request({
    url: '/iot/insuranceObjects',
    method: 'put',
    data: data
  })
}

// 删除保险受保对象管理
export function delInsuranceObjects (id) {
  return request({
    url: '/iot/insuranceObjects/' + id,
    method: 'delete'
  })
}

// 导出保险受保对象管理
export function exportInsuranceObjects (query) {
  return request({
    url: '/iot/insuranceObjects/export',
    method: 'get',
    params: query
  })
}
