<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="保单号" prop="insuranceNo">
        <a-input v-model="form.insuranceNo" placeholder="请输入保单号" />
      </a-form-model-item>
      <a-form-model-item label="受保对象类型" prop="kind">
        <a-radio-group v-model="form.kind" button-style="solid">
          <a-radio-button v-for="(d, index) in kindOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="受保对象" prop="kindId">
        <!-- <a-input v-model="form.kindId" placeholder="请输入受保对象ID" /> -->
        <template v-if="form.kind == 'vehicle'">
          <a-form-model-item label="车辆自编号" prop="vehicleNo">
            <a-select
              show-search
              allowClear
              placeholder="请输入车辆自编号模糊查找"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleVehicleNoSearch"
              @change="handleVehicleNoChange"
              :loading="loading"
              v-model="form.vehicleNo"
            >
              <a-select-option v-for="d in vehicleNoArray" :key="d.vehicleNo" :value="d.vehicleNo">
                {{ d.vehicleNo }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="车辆车牌号" prop="licensePlateNum">
            <a-select
              show-search
              allowClear
              placeholder="请输入车辆车牌号模糊查找"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleLicensePlateNumSearch"
              @change="handleLicensePlateNumChange"
              :loading="loading"
              v-model="form.licensePlateNum"
            >
              <a-select-option v-for="d in vehicleNoArray" :key="d.licensePlateNum" :value="d.licensePlateNum">
                {{ d.licensePlateNum }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </template>
        <template v-else>
          <a-button type="primary"> 导入 </a-button>
        </template>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getInsuranceObjects, addInsuranceObjects, updateInsuranceObjects } from '@/api/iot/insuranceObjects'
import debounce from 'lodash/debounce'
import { searchCarNo } from '@/api/iot/vehicleAccount'

export default {
  name: 'CreateForm',
  props: {
    kindOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {},
  data() {
    this.handleVehicleNoSearch = debounce(this.handleVehicleNoSearch, 500)
    this.handleLicensePlateNumSearch = debounce(this.handleLicensePlateNumSearch, 500)
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        insuranceNo: null,
        kind: 'vehicle',
        kindId: null,
        status: 0
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        insuranceNo: [{ required: true, message: '保单号不能为空', trigger: 'blur' }],
        kind: [{ required: true, message: '受保对象类型不能为空', trigger: 'blur' }],
        kindId: [{ required: true, message: '受保对象ID不能为空', trigger: 'blur' }]
      }
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        insuranceNo: null,
        kind: 'vehicle',
        kindId: null,
        status: 0
      }
    },
    /** 新增按钮操作 */
    handleAdd(curInsuranceNo) {
      console.log('curInsuranceNo', curInsuranceNo)
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      this.form.insuranceNo = curInsuranceNo
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getInsuranceObjects(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateInsuranceObjects(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addInsuranceObjects(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    },
    handleVehicleNoSearch(value) {
      console.log('handleVehicleNoSearch', value)
      const queryParam = {
        vehicleNo: value
      }
      this.loading = true
      searchCarNo(queryParam).then(response => {
        this.vehicleNoArray = response
        this.loading = false
      })
    },
    handleVehicleNoChange(value) {
      this.form.vehicleNo = value
      // 设置车牌号
      const tmpArray = this.vehicleNoArray.filter(p => p.vehicleNo === value)
      if (tmpArray.length > 0) {
        this.form.licensePlateNum = tmpArray[0].licensePlateNum
        this.$forceUpdate()
      }
    },
    handleLicensePlateNumSearch(value) {
      console.log('handleLicensePlateNumSearch', value)
      const queryParam = {
        licensePlateNum: value
      }
      this.loading = true
      searchCarNo(queryParam).then(response => {
        this.vehicleNoArray = response
        this.loading = false
      })
    },
    handleLicensePlateNumChange(value) {
      this.form.licensePlateNum = value
      // 设置车辆自编号
      const tmpArray = this.vehicleNoArray.filter(p => p.licensePlateNum === value)
      if (tmpArray.length > 0) {
        this.form.vehicleNo = tmpArray[0].vehicleNo
        this.$forceUpdate()
      }
    }
  }
}
</script>
