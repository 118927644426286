var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "保单号", prop: "insuranceNo" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入保单号" },
                model: {
                  value: _vm.form.insuranceNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "insuranceNo", $$v)
                  },
                  expression: "form.insuranceNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "受保对象类型", prop: "kind" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.kind,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "kind", $$v)
                    },
                    expression: "form.kind",
                  },
                },
                _vm._l(_vm.kindOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "受保对象", prop: "kindId" } },
            [
              _vm.form.kind == "vehicle"
                ? [
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "车辆自编号", prop: "vehicleNo" } },
                      [
                        _c(
                          "a-select",
                          {
                            attrs: {
                              "show-search": "",
                              allowClear: "",
                              placeholder: "请输入车辆自编号模糊查找",
                              "default-active-first-option": false,
                              "show-arrow": false,
                              "filter-option": false,
                              "not-found-content": null,
                              loading: _vm.loading,
                            },
                            on: {
                              search: _vm.handleVehicleNoSearch,
                              change: _vm.handleVehicleNoChange,
                            },
                            model: {
                              value: _vm.form.vehicleNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "vehicleNo", $$v)
                              },
                              expression: "form.vehicleNo",
                            },
                          },
                          _vm._l(_vm.vehicleNoArray, function (d) {
                            return _c(
                              "a-select-option",
                              {
                                key: d.vehicleNo,
                                attrs: { value: d.vehicleNo },
                              },
                              [_vm._v(" " + _vm._s(d.vehicleNo) + " ")]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: { label: "车辆车牌号", prop: "licensePlateNum" },
                      },
                      [
                        _c(
                          "a-select",
                          {
                            attrs: {
                              "show-search": "",
                              allowClear: "",
                              placeholder: "请输入车辆车牌号模糊查找",
                              "default-active-first-option": false,
                              "show-arrow": false,
                              "filter-option": false,
                              "not-found-content": null,
                              loading: _vm.loading,
                            },
                            on: {
                              search: _vm.handleLicensePlateNumSearch,
                              change: _vm.handleLicensePlateNumChange,
                            },
                            model: {
                              value: _vm.form.licensePlateNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "licensePlateNum", $$v)
                              },
                              expression: "form.licensePlateNum",
                            },
                          },
                          _vm._l(_vm.vehicleNoArray, function (d) {
                            return _c(
                              "a-select-option",
                              {
                                key: d.licensePlateNum,
                                attrs: { value: d.licensePlateNum },
                              },
                              [_vm._v(" " + _vm._s(d.licensePlateNum) + " ")]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : [
                    _c("a-button", { attrs: { type: "primary" } }, [
                      _vm._v(" 导入 "),
                    ]),
                  ],
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }